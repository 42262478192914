.App {
  text-align: center;
}
.App-background {
  background-color: #efe2ba;
  min-height: 100vh;
}
.App-logo {
  height: 40vmin;
}

.deskBackground {
  background-image: url('building_front.jpg') !important;
  background-size: cover !important;
  min-height: 100vh;
}

.App-header {
  background-color: #efe2ba;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #09d3ac;
}


.content {
  margin-top: 10px;
  margin-left: 10px;
  margin-right: 10px;
}

.content-order {
  margin-top: 80px;
  margin-left: 80px;
  margin-right: 80px;
}

.roundElement	{
	border-radius: 5px;
}

.navbar-menu {
  background-color: #B90E0F;
}

.menu-pics {
  padding-left: 10%;
  padding-right: 10%;
}

.no-link-color {
  color:inherit;
}

.no-link-color:hover {
  color:inherit;
}